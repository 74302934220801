.about-header {
    background-color: #325075;
    padding: 7vh 7vh 7vh 7vh;
}

#about-header {
    font-size: 60px;
    color: white;
}

.about {
    display: flex;
    justify-content: center;
    padding: 7vh;
}

#tom {
    width: 450px;
    border-radius: 7px;
  }


.about-picture {

}

.about-paragraph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 7vh;
    padding-top: 5vh;
    max-width: 600px;
}


@media only screen and (max-width: 440px){
    .about {
        flex-direction: column;
        padding: 2vh;
    }

    #about-header {
        font-size: 40px;
    }

    #tom {
        width: 300px;
    }

    .about-paragraph {
        padding: 3vh 0vh 3vh 0vh;
    }

    .about-header {
        margin-top: -1px;
        padding: 2vh;
    }


}