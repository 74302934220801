.contact-page {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    padding: 7vh;
}

#contact-input {
    padding-bottom: 10px;
}

#contact-input2 {
    padding: 2vh;
    font-size: 20px;
    
}

input {
    padding: 2vh;
    width: 200px;
    font-size: 20px;
}

.input-boxes {

}

button {
    border-color: black;
    width: 100px;
    border-spacing: 10px;
    padding: 10px;
    background-color: black;
    text-shadow: none;
    color: white;
}

button:hover {
    background-color: #212528;
    cursor: pointer;
}



@media only screen and (max-width: 440px){
    .contact-page {
        padding: 3vh 2vh 3vh 2vh;
    }
}