.footer {
    background-color: white;
    padding: 20vh 7vh 7vh 7vh;
    text-align: center;
}

#footer-logo {
    font-size: 22px;
    color: black;
}


.footer-contact {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 40px;
}

#footer-contact {
    color: black;
    font-size: 20px;
    font-weight: 100;
}

.footer-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

#footer-icons {
    font-size: 30px;
    color: black;
    padding-bottom: 12px;
}

@media only screen and (max-width: 440px){
    .footer {
        padding: 15vh 3vh 3vh 3vh;
    }

    .footer-contact {
        display: flex;
        flex-direction: column;
    }
}