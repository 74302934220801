.services {
    display: flex;
    padding: 7vh;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
}

h3.services {
    white-space: pre;
}


.services-header {
    text-align: center;
    padding: 7vh 1vh 7vh 1vh;
}

.services-equipment {
    display: grid;
    gap: 10px;
}

#list {
    font-size: 19px;
    font-weight: 100;
    padding-top: 10px;
}


@media only screen and (max-width: 440px){
    .services {
        padding: 2vh;
    }

    .services-header {
        padding: 3vh 1vh 3vh 1vh;
    }
}