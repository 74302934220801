@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&family=Rampart+One&family=Roboto+Mono:wght@100&family=Tenor+Sans&family=Tiro+Devanagari+Sanskrit&family=Titillium+Web:wght@300;400;700&family=Trocchi&display=swap');
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@300&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@700,300,400&f[]=gambetta@400,300,700&f[]=literata@200,400,700,900,300&display=swap");
* {
  font-family: 'Satoshi', sans-serif;
  margin: 0;
}

h3 {
  font-weight: 100;
}

.space {
  padding: 7vh;
}


.headline {
  text-align: center;

}

#headline {
  padding-bottom: 20px;
  
}

#headline {
  color: black;
  max-width: 840px;

}

.main-headline {
  text-align: center;
  padding: 3vh;
  background-color: #E39C53;
  opacity: .85;
  
}

.profile-photo {
  display: flex;
  justify-content: center;
}

.body {
  display: flex;
  justify-content: center;
  background-color: white;
  background-image: url('./images/front-page.png');
  background-attachment: fixed;
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat;
  height: 700px;
  padding-top: 15vh;
}

#pt {
  width: 100%;
}


.paragraph2 {
  display: flex;
  justify-content: center;
  padding: 3vh;
  background-color: grey;
  opacity: .85;
}

#paragraph3 {
  color: white;
}

.paragraph3 {
  max-width: 840px;
}

@media only screen and (max-width: 440px){
  .body {
    background-position: 20%;
  }

  #logo {
    font-size: 20px;
  }

  #icon {
    width: 20px;
  }

  .space {
    padding: 1.5vh;
  }

  .main-headline {
    padding: 1vh;
  }

  #headline {
    font-size: 20px;
    padding-bottom: 0px;
  }

  .body {
    padding: 3vh 1vh 5vh 1vh;
  }

  .headline {
    /* padding: 1vh; */
  }

  #pt {
    width: 100%;
  }

  h1 {
    font-size: 22px;
  }

}