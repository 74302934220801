.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7vh;
    background-color: #325075;
  
  }

  a:link {
    text-decoration: none!important;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }

  #logo {
    color: white;
    /* min-width: 200px; */
    font-size: 40px;
  }

  #icon {
    width: 70px;
    padding-top: 5px;
    padding-right: 12px;
  }
  
  .pages {
    display: flex;
    padding-left: 15vh;
    gap: 2rem;
  }
  
  #pages {
    text-decoration: none;
    color: white;
    font-weight: 100;
    font-size: 30px;
  }


  @media only screen and (max-width: 440px){

      .navbar {
        padding: 1vh 1vh 1vh 2vh;
      }

      #logo {
        font-size: 20px;
      }

      #icon {
        width: 20px;
      }
  }
