.faq {
    display: flex;
    flex-direction: column;
    padding: 7vh;
    max-width: 1000px;
}


@media only screen and (max-width: 440px){
    .faq {
        padding: 3vh 2vh 3vh 2vh;
    }
}
